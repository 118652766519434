.custom-rbc .rbc-allday-cell {
  display: none;
}

.custom-rbc .rbc-time-view .rbc-header {
  padding: 8px 0 0;
  border-bottom: none;
}

.custom-rbc .rbc-time-view .rbc-header .rbc-button-link {
  pointer-events: none;
}

.custom-rbc .rbc-today {
  background-color: #fbfbfb;
}

.custom-rbc .rbc-day-slot .rbc-timeslot-group:nth-child(even) {
  background-color: #f4f4f4;
}

.custom-rbc .rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.custom-rbc .rbc-time-header-gutter,
.custom-rbc .rbc-current-time-indicator {
  display: none;
}

.custom-rbc .rbc-time-column:nth-child(2) .rbc-timeslot-group {
  border-left: none;
}

.custom-rbc .rbc-time-header-content {
  border-left: 1px solid transparent;
}

.custom-rbc .rbc-timeslot-group {
  border-bottom: none;
  min-height: 120px;
}

.custom-rbc .rbc-label {
  padding: 0;
  font-size: 10px;
}

.custom-rbc .rbc-time-slot {
  display: flex;
  justify-content: flex-end;
}

.custom-rbc .rbc-time-content > .rbc-time-gutter {
  position: absolute;
  z-index: 1;
  left: -55px;
}

.custom-rbc .rbc-time-content {
  border-top: none;
  overflow: initial;
  align-items: stretch;
}

.custom-rbc .rbc-calendar {
  flex: 1;
}

.custom-rbc .rbc-day-slot {
  cursor: pointer;
}

.custom-rbc .rbc-day-slot .rbc-events-container {
  margin-left: 8px;
  margin-right: 0;
}

.custom-rbc .rbc-day-slot .rbc-event-label {
  padding-right: 36px;
}

.custom-rbc .rbc-time-header {
  position: sticky;
  top: 64px;
  z-index: 1;
  border-bottom: 1px solid #ddd;
  background-color: white;
}

.custom-rbc .rbc-event-label {
  display: none;
}

.rbc-event {
  display: grid;
  justify-items: center;
}
