.content-transition-enter {
    opacity: 0;
    transform: rotateY(-180deg);
    /* transform-origin: 100% 0%; */
    transition: opacity 1s, transform 1s;
}

.content-transition-enter-active {
    opacity: 1;
    transform: rotateY(0deg);
}

.content-transition-exit {
    opacity: 1;
    transform: rotateY(0deg);
    transition: opacity 1s, transform 1s;
}

.content-transition-exit-active {
    opacity: 0;
    transform: rotateY(180deg);
}