.nowLearningSectionSlider .slick-list {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  display: -webkit-box;
  max-height: 230px;
}

.nowLearningSectionSlider .slick-prev:before,
.slick-next:before {
  color: #98c4c5;
}

.nowLearningSectionSlider .slick-slide {
  display: flex !important;
  align-items: stretch;
}

.nowLearningSectionSlider .slick-slide > div {
  display: grid;
  place-items: center;
  width: 80%;
  height: 100%;
  margin: auto;
}
