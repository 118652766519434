.btn {
  all: unset;
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  user-select: none;
  box-sizing: border-box;
  /* font-family: "Jost", sans-serif; */
  font-family: "Futura PT", sans-serif;
}

.btn:hover {
  transform: translateX(3px);
  transition: all 100ms ease-in-out;
}

.outlined {
  border: 2px solid var(--txt-secondary);
  background-color: transparent;
  color: var(--txt-secondary);
}

.filled {
  background-color: var(--secondary-bg-color);
  color: var(--txt-light);
}

.filledLight {
  background-color: var(--txt-light);
  color: var(--txt-dark2);
  border: 2px solid var(--secondary-bg-color);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.disabled:hover {
  transform: none;
}

.btn2 {
  font-weight: 500;
  width: 100%;
  gap: 6px;
}

.download_btn {
  padding: 8px 16px;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
  font-size: 14px;
}

.browse_project_btn {
  border-radius: 5px;
  font-weight: 500;
}
