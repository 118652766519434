.loginBtn {
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 100ms ease-in-out;
}
.loginBtn:hover {
  transform: scale(0.95);
  transition: transform 100ms ease-in-out;
}
