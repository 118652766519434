body.drawer-open {
  overflow: hidden;
}

body.is-dragging {
  cursor: col-resize;
}

body.is-dragging .main {
  user-select: none;
  pointer-events: none;
}

body.is-dragging .resizer {
  opacity: 1;
}

body.is-dragging .drawer-overlay {
  opacity: 0.2;
}
