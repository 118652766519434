#lesson-activity-page-main-content:fullscreen > div {
  height: 100%;
  width: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* animations.css */

.animated-text-enter {
  transform: translateX(0);
}

.animated-text-enter-active {
  animation: shake 2.0s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  animation-iteration-count: infinite;
}

@keyframes shake {

  /* 10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  } */
  20%, 
  30%,
  50%,
  70% {
    transform: translate3d(-16px, 0, 0);
  }
  40%,
  60%,
  80% {
    transform: translate3d(16px, 0, 0);
  }
}

/* .animated-text-exit {
  opacity: 1;
}

.animated-text-exit-active {
  opacity: 0;
  transition: opacity 500ms;
} */
