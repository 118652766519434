@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ZCOOL+KuaiLe&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap");

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 300;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicLight.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicBook.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 500;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicDemi.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 600;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicHeavy.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 700;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicBold.ttf") format("truetype");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 800;
  src: local("Futura PT"),
    url("./fonts/FuturaCyrillicExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "KaiTi";
  src: local("KaiTi"), url("./fonts/KaiTi.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaLtBT";
  src: local("FuturaLtBT"), url("./fonts/FuturaLtBT.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaBkBT";
  src: local("FuturaBkBT"), url("./fonts/FuturaBkBT.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaMediumBT";
  src: local("FuturaMediumBT"),
    url("./fonts/FuturaMediumBT.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansSC-Medium";
  src: local("NotoSansSC-Medium"),
    url("./fonts/NotoSansSC-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaMedium";
  src: local("FuturaMedium"), url("./fonts/FuturaMedium.otf") format("opentype");
}

@font-face {
  font-family: "TheOnlyException";
  src: local("TheOnlyException"),
    url("./fonts/TheOnlyException.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansSC";
  src: local("NotoSansSC"), url("./fonts/NotoSansSC.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansSCLight";
  src: local("NotoSansSCLight"),
    url("./fonts/NotoSansSCLight.ttf") format("truetype");
}
:root {
  -webkit-tap-highlight-color: transparent;
  --main-text-color: #000;
  --main-bg-color: #fff;
  --secondary-bg-color: #98c4c5;
  --txt-dark: #000;
  --txt-dark2: #444;
  --txt-light: #fff;
  --txt-secondary: #407374;
  --primary-light: #d1e4e3;
  --primary-main: #98c4c5;
  /* --primary-contrast: #358181; */
  --primary-contrast: rgba(152, 196, 197, 1);
  --primary-analogous-1: #98aec5;
  --primary-analogous-2: #98c5af;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 'Noto Serif SC', */
  font-family: "Futura PT", "KaiTi", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-width: thin;
  scrollbar-color: var(--primary-main) var(--primary-light);
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: var(--primary-light);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-main);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--txt-secondary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  object-fit: contain;
}

li {
  margin-left: 20px;
}

/* To make sure the edit image always infront */
.ck.ck-balloon-panel {
  z-index: 9999 !important;
}

/* Scrollbar globally width for mobile */
@media (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}


#scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .MuiBox-root::-webkit-scrollbar-button {
    display: none;
  }
}