.childrenPhotoSectionSlider .slick-list {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  display: -webkit-box;
  max-height: 230px;
}

.childrenPhotoSectionSlider .slick-prev:before,
.slick-next:before {
  color: #98c4c5;
}

.childrenPhotoSectionSlider .slick-slide {
  display: flex !important;
  align-items: stretch;
}

.childrenPhotoSectionSlider :not(.slick-center) > div {
  transform: scale(0.9);
}
.childrenPhotoSectionSlider .slick-center > div {
  transform: scale(1.1);
}
