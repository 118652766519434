#ReactGridGallery .ReactGridGallery_tile {
  background: transparent !important;
}

#ReactGridGallery .ReactGridGallery_custom-overlay,
#ReactGridGallery .ReactGridGallery_tile:hover .custom-overlay,
#ReactGridGallery .ReactGridGallery_tile:last-of-type .number-overlay {
  opacity: 1 !important;
}

#ReactGridGallery
  .ReactGridGallery_tile:last-of-type
  .number-overlay
  + .custom-overlay,
#ReactGridGallery .ReactGridGallery_tile:not(:last-of-type) .number-overlay {
  display: none;
}

#ReactGridGallery .ReactGridGallery_tile-viewport {
  cursor: pointer;
}

#ReactGridGallery .custom-overlay,
#ReactGridGallery .number-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  opacity: 0;
  transition: all 200ms linear;
}

#ReactGridGallery .ReactGridGallery_custom-overlay p {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.project-summary-project-moments #ReactGridGallery > div {
  justify-content: center;
}

.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile:last-of-type {
  pointer-events: auto;
}

.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile:last-of-type:hover
  .number-overlay
  p,
.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile
  .number-overlay
  p.view-all {
  display: none;
}

.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile:last-of-type:hover
  .number-overlay
  p.view-all {
  display: block;
}

.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile-viewport
  > div {
  width: 100%;
}

.project-summary-project-moments
  #ReactGridGallery
  .ReactGridGallery_tile-viewport
  img {
  max-width: 100% !important;
  min-width: 0 !important;
}

#ReactGridGallery > div {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 4px;

  @media screen and (min-width: 410px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: 600px) {
    display: flex !important;
  }
}

#ReactGridGallery > div > .ReactGridGallery_tile .ReactGridGallery_tile-viewport > div {
  width: 100% !important;

  @media screen and (min-width: 600px) {
    width: 120px !important;
  }
}

#ReactGridGallery>div>.ReactGridGallery_tile .ReactGridGallery_tile-viewport > div > img {
  width: 100% !important;
  max-width: 100% !important;

  @media screen and (min-width: 600px) {
    width: 120px !important;
    max-width: 120px !important;
  }
}